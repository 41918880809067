<script setup lang="ts">
import { authState, authStatus, hasRettighed } from '@/letapps-vue/utils/auth';
import Error401Page from '@/letapps-vue/pages/Error401Page.vue';
import LoginPage from '@/letapps-vue/pages/LoginPage.vue';
import LoginFailedPage from '@/letapps-vue/pages/LoginFailedPage.vue';
import LoginPendingPage from '@/letapps-vue/pages/LoginPendingPage.vue';
import LoginRegisterPage from '@/letapps-vue/pages/LoginRegisterPage.vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const title = 'Administrationsportal';
</script>

<template>
  <div class="app">
    <Error401Page
      v-if="
        authState.status === authStatus.AUTHORIZED &&
        route.meta.rettighed &&
        !hasRettighed(route.meta.rettighed as string)
      "
    />

    <component v-else-if="authState.status === authStatus.AUTHORIZED" :is="$route.meta.layout">
      <div class="content">
        <RouterView></RouterView>
      </div>
    </component>

    <LoginPage v-else-if="authState.status === authStatus.UNAUTHENTICATED" :title="title" />
    <LoginRegisterPage
      v-else-if="authState.status === authStatus.USERCHECK_UNREGISTERED"
      :title="title"
    />
    <LoginFailedPage v-else-if="authState.failed" :title="title" />
    <LoginPendingPage v-else :title="title" />
  </div>
</template>

<style lang="scss">
html,
body {
  margin: 0;
  min-height: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  display: flex;
  height: 100%;
  width: 100%;
}
</style>
