<script setup lang="ts">
import { DxColumn, DxDataGrid, DxFilterRow, DxPaging, DxScrolling } from 'devextreme-vue/data-grid';
import { getLogDatasource } from '@/data/logDatasource';
import { useRouter } from 'vue-router';
import { ROUTE_LOG } from '@/router';
import LogLevelIcon from '@/pages/log/LogLevelIcon.vue';
import type { RowClickEvent } from 'devextreme/ui/data_grid';

const router = useRouter();

const logDatasource = getLogDatasource();

function onRowClick(e: RowClickEvent) {
  const logId = e.data.id;
  router.push(`${ROUTE_LOG}/${logId}`);
}
</script>

<template>
  <DxDataGrid
    :data-source="logDatasource"
    :remote-operations="true"
    :row-alternation-enabled="true"
    :show-column-lines="false"
    :show-borders="true"
    :allow-column-resizing="true"
    @row-click="onRowClick"
    class="grid"
  >
    <DxScrolling mode="virtual" row-rendering-mode="virtual" />
    <DxPaging :page-size="100" />

    <DxFilterRow :visible="true" />

    <template #levelTemplate="{ data }">
      <log-level-icon :level="data.value" />
    </template>

    <DxColumn
      data-field="timeStamp"
      caption="Tid"
      data-type="date"
      format="dd-MM-yyyy HH:mm:ss"
      cssClass="numbers"
      :width="190"
      sort-order="desc"
    />
    <DxColumn data-field="level" caption="Level" :width="60" cell-template="levelTemplate" />
    <DxColumn data-field="application" caption="Application" :width="120" />
    <DxColumn data-field="user" caption="User" :width="120" />
    <DxColumn data-field="requestMethod" caption="Method" :width="80" />
    <DxColumn data-field="requestPath" caption="Path" :width="200" />
    <DxColumn data-field="message" caption="Message" />
  </DxDataGrid>
</template>

<style scoped lang="scss">
.grid {
  height: 100%;
  min-height: 0;
}
</style>
data/logDatasource
