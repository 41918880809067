<script setup lang="ts">
import {
  DxColumn,
  DxDataGrid,
  DxFormItem,
  DxLookup,
  DxPaging,
  DxScrolling,
  DxSearchPanel,
  DxToolbar,
} from 'devextreme-vue/data-grid';
import { ref } from 'vue';
import { DxDropDownOptions } from 'devextreme-vue/lookup';
import { DxButton } from 'devextreme-vue/button';
import { DxItem } from 'devextreme-vue/toolbar';
import { getOrganisationDatasource } from '@/data/organisationDatasource';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { getRolleDatasource } from '@/data/rolleDatasource';
import { getBrugerDatasource } from '@/data/brugerDatasource';

import { ROUTE_BRUGERE } from '@/router';
import { useRouter } from 'vue-router';
import type { SelectionChangedEvent } from 'devextreme/ui/select_box';
import type { RowClickEvent } from 'devextreme/ui/data_grid';
import type { LetCustomStore } from '@/letapps-vue/utils/useRestApi';

const router = useRouter();

const grid = ref();
const orgData = await getOrganisationDatasource().load();
const brugerDatasource = getBrugerDatasource();
const brugerDatasourceStore = brugerDatasource.store() as LetCustomStore;
brugerDatasourceStore.userData = {};
const rolleDatasource = getRolleDatasource();

function onRowClick(e: RowClickEvent) {
  const brugerId = e.data.id;
  router.push(`${ROUTE_BRUGERE}/${brugerId}`);
}

function opretBruger() {
  router.push(`${ROUTE_BRUGERE}/-1`);
}

function searchRolle(e: SelectionChangedEvent) {
  brugerDatasourceStore.userData.rolleId = e.selectedItem?.id;
  grid.value.instance.refresh();
}
</script>

<template>
  <DxDataGrid
    ref="grid"
    :data-source="brugerDatasource"
    :remote-operations="true"
    :focused-row-enabled="true"
    :row-alternation-enabled="true"
    :show-column-lines="false"
    :hover-state-enabled="true"
    :show-borders="true"
    @row-click="onRowClick"
    height="100%"
  >
    <DxPaging :page-size="100" />
    <DxScrolling mode="virtual" row-rendering-mode="virtual" />
    <DxSearchPanel :visible="true" :highlight-search-text="false" placeholder="Søg..." />
    <DxToolbar>
      <DxItem location="before">
        <DxButton
          text="Opret bruger"
          icon="plus"
          @click="opretBruger"
          type="success"
          styling-mode="outlined"
        />
      </DxItem>
      <DxItem location="after">
        <DxSelectBox
          :dataSource="rolleDatasource"
          placeholder="Rolle"
          valueExpr="id"
          displayExpr="navn"
          :searchEnabled="true"
          :width="250"
          :show-clear-button="true"
          @selection-changed="searchRolle"
        >
        </DxSelectBox>
      </DxItem>
      <DxItem name="searchPanel" location="after" />
    </DxToolbar>
    <DxColumn data-field="fornavn" sort-order="asc" />
    <DxColumn data-field="efternavn" />
    <DxColumn data-field="email" />
    <DxColumn data-field="organisationId" caption="Organisation" data-type="number">
      <DxLookup :data-source="orgData" value-expr="id" display-expr="navn">
        <DxDropDownOptions :defer-rendering="false" />
      </DxLookup>
    </DxColumn>
    <DxColumn
      data-field="sidsteLogin"
      caption="Sidste login"
      data-type="date"
      format="dd-MM-yyyy HH:mm:ss"
      css-class="numbers"
    >
      <DxFormItem :visible="false" />
    </DxColumn>
  </DxDataGrid>
</template>

<style></style>
