<script setup lang="ts">
import axios from 'axios';
import { DxButton } from 'devextreme-vue/button';
import { DxForm, DxLabel, DxSimpleItem } from 'devextreme-vue/form';
import { DxItem, DxToolbar } from 'devextreme-vue/toolbar';
import LetButton from '@/letapps-vue/components/LetButton.vue';
import LetPage from '@/letapps-vue/components/LetPage.vue';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import { ref } from 'vue';
import { getErrorMessage } from '@/letapps-vue/utils/errorUtils';

interface Data {
  udfoerSletning?: number;
  cpr?: string;
}
const sletRevisionForm = ref();
const data = ref<Data>({});
const fejlbesked = ref('');
const besked = ref('');
const loading = ref(false);
const sletningTilladt = ref(false);

async function sletRevision(udfoerSletning = 0) {
  data.value.udfoerSletning = udfoerSletning;
  data.value.cpr = data.value.cpr?.replaceAll('-', '');
  fejlbesked.value = '';
  besked.value = '';
  const validation = sletRevisionForm.value.instance.validate();
  if (validation.isValid === false) return;

  loading.value = true;
  try {
    const response = await axios.get(`${getAppConfig().apiRoot}/api/support/sletrevision`, {
      params: data.value,
    });
    if (!response.data.success) {
      sletningTilladt.value = false;
      fejlbesked.value = response.data.message;
    } else {
      if (udfoerSletning === 1) {
        sletningTilladt.value = false;
      } else {
        sletningTilladt.value = true;
      }
      besked.value = response.data.message;
    }
  } catch (e) {
    fejlbesked.value = getErrorMessage(e);
    loading.value = false;
    return;
  }
  loading.value = false;
}

function fortryd() {
  data.value = {};
}

function markDirty() {
  sletningTilladt.value = false;
}
</script>

<template>
  <LetPage :fit="true" title="Slet revision">
    <DxForm
      ref="sletRevisionForm"
      :form-data="data"
      :showValidationSummary="true"
      :width="500"
      @fieldDataChanged="
        () => {
          sletningTilladt = false;
        }
      "
    >
      <DxSimpleItem
        data-field="cpr"
        :is-required="true"
        :editorOptions="{
          valueChangeEvent: 'keyup',
          onValueChanged: markDirty,
        }"
      />
      <DxSimpleItem
        data-field="letsikringNr"
        :is-required="true"
        :editorOptions="{
          valueChangeEvent: 'keyup',
          onValueChanged: markDirty,
        }"
      />
      <DxSimpleItem
        data-field="revisionNr"
        :is-required="true"
        :editorOptions="{
          valueChangeEvent: 'keyup',
          onValueChanged: markDirty,
        }"
      />
      <DxSimpleItem>
        <DxLabel text=" " :showColon="false" />
        <DxToolbar>
          <DxItem location="before">
            <LetButton
              :loading="loading"
              text="Valider sletning"
              :use-submit-behavior="false"
              @click="sletRevision(0)"
              type="default"
            />
          </DxItem>
          <DxItem location="before">
            <LetButton
              :loading="loading"
              text="Slet revision"
              :use-submit-behavior="false"
              @click="sletRevision(1)"
              type="default"
              :disabled="!sletningTilladt"
            />
          </DxItem>
          <DxItem location="before">
            <DxButton text="Nulstil" @click="fortryd" />
          </DxItem>
        </DxToolbar>
      </DxSimpleItem>
      <DxSimpleItem>
        <DxLabel text=" " :showColon="false" />
        <div class="color-error">{{ fejlbesked }}</div>
      </DxSimpleItem>
      <DxSimpleItem>
        <DxLabel text=" " :showColon="false" />
        <div>{{ besked }}</div>
      </DxSimpleItem>
    </DxForm>
  </LetPage>
</template>

<style scoped lang="scss"></style>
