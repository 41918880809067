<script setup lang="ts">
import { computed } from 'vue';
import LetPage from '@/letapps-vue/components/LetPage.vue';
import BrugereGrid from './BrugereGrid.vue';
import { useRoute } from 'vue-router';
import BrugerDetail from '@/pages/adgang/BrugerDetail.vue';

const route = useRoute();
const brugerId = computed(() => Number(route.params.id));
const showDetails = computed(() => !!route.params.id);
</script>

<template>
  <LetPage :fit="true" title="Brugere">
    <div v-show="!showDetails" class="brugere-grid-container">
      <Suspense>
        <BrugereGrid />
      </Suspense>
    </div>
    <div v-if="showDetails" class="brugere-details-container">
      <BrugerDetail :brugerId="brugerId" />
    </div>
  </LetPage>
</template>

<style scoped lang="scss">
.brugere-grid-container {
  height: 100%;
}
.brugere-details-container {
  height: 100%;
  overflow: auto;
}
</style>
