<script setup lang="ts">
import axios from 'axios';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import { onMounted, ref } from 'vue';
import type { BrugerNotifikation } from '@/letapps-vue/types/brugerNotifikation';
import type { LoadResultObject } from 'devextreme/common/data/custom-store';

const props = defineProps({
  brugerId: { type: Number },
});

const brugerNotifikationer = ref<BrugerNotifikation[]>([]);

onMounted(async () => {
  const { apiRoot } = getAppConfig();
  const URL = `${apiRoot}/api/BrugerNotifikationer/bruger/${props.brugerId}`;

  const response = await axios.get<LoadResultObject<BrugerNotifikation>>(URL);
  const data = response.data.data as BrugerNotifikation[];
  brugerNotifikationer.value = data.sort((a, b) =>
    a.notifikationType.navn > b.notifikationType.navn
      ? 1
      : b.notifikationType.navn > a.notifikationType.navn
        ? -1
        : 0,
  );
});
</script>

<template>
  <div>
    <div v-if="brugerNotifikationer.length == 0">
      Brugeren abonnerer ikke på nogen notifikationer
    </div>
    <div v-else v-for="n in brugerNotifikationer" :key="n.id">{{ n.notifikationType.navn }}</div>
  </div>
</template>

<style scoped>
.list {
  height: 100%;
}
</style>
