<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import DxScrollView from 'devextreme-vue/scroll-view';
import { getRettighedDatasource } from '@/data/rettighedDatasource';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { getRolleRettighedDatasource } from '@/data/rolleRettighedDatasource';
import type { ValueChangedEvent } from 'devextreme/ui/check_box';
import type { Rettighed } from '@/letapps-vue/types/rettighed';
import type { RolleRettighed } from '@/letapps-vue/types/rolleRettighed';

const props = defineProps({
  rolle: {
    type: Object,
    required: true,
  },
});

interface RettighedData extends Rettighed {
  rolleRettighed?: RolleRettighed;
}
const rettighedData = ref<RettighedData[]>([]);

const rettigheder = ref<Rettighed[]>();

const rolleRettighedDatasource = getRolleRettighedDatasource();
const rolleRettigheder = ref<RolleRettighed[]>();
const ready = ref(false);

onMounted(async () => {
  rettigheder.value = await getRettighedDatasource().load();
});

watchEffect(async () => {
  rolleRettighedDatasource.filter(['rolleId', '=', props.rolle.id]);
  rolleRettigheder.value = await rolleRettighedDatasource.load();

  rettighedData.value = [];
  rettigheder.value?.forEach((r) => {
    rettighedData.value.push({ ...r, rolleRettighed: getRolleRettighed(r.name) });
  });

  ready.value = true;
});

function getRolleRettighed(rettighedName: string) {
  if (!rolleRettigheder.value) return undefined;
  const found = rolleRettigheder.value.filter(
    (rolleRettighed) => rolleRettighed.rettighed === rettighedName,
  );
  if (found.length > 0) {
    return found[0];
  }
  return undefined;
}

async function grant(
  rettighedName: string,
  rolleRettighed: RolleRettighed | undefined,
  e: ValueChangedEvent,
) {
  if (!e.event) return; // Vi skal kun gøre noget, hvis en bruger har klikket.
  if (e.value === false && rolleRettighed) {
    rolleRettighedDatasource.store().remove(rolleRettighed.id);
  }
  if (e.value === true) {
    await rolleRettighedDatasource.store().insert({
      rolleId: props.rolle.id,
      rettighed: rettighedName,
    });
    rolleRettigheder.value = await rolleRettighedDatasource.reload();
  }
}
</script>

<template>
  <div>
    <fieldset class="fieldset">
      <legend>
        Rettigheder:
        <span class="l-bold l-mb">{{ rolle.navn }}</span>
      </legend>

      <DxScrollView v-if="ready">
        <div>
          <div v-for="rettighed in rettighedData" :key="rettighed.name">
            <DxCheckBox
              :value="rettighed.rolleRettighed != null"
              @valueChanged="
                (e: ValueChangedEvent) => grant(rettighed.name, rettighed.rolleRettighed, e)
              "
            />
            {{ rettighed.description }} <span class="rettighed-name">[{{ rettighed.name }}]</span
            ><br />
          </div>
        </div>
      </DxScrollView>
    </fieldset>
  </div>
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension.scss';

.fieldset {
  height: 100%;
}

.rettighed-name {
  color: $letpension-grey;
}
</style>
data/rettighedDatasourcedata/rolleRettighedDatasource
