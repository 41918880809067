<script setup lang="ts">
import { getBrugerDatasource } from '@/data/brugerDatasource';
import LetBackButton from '@/letapps-vue/components/LetBackButton.vue';
import LetButton from '@/letapps-vue/components/LetButton.vue';
import type { Bruger } from '@/letapps-vue/types/bruger';
import BrugerHistorik from '@/pages/adgang/BrugerHistorik.vue';
import BrugerNotifikationer from '@/pages/adgang/BrugerNotifikationer.vue';
import BrugerRolleList from '@/pages/adgang/BrugerRolleList.vue';
import { ROUTE_BRUGERE } from '@/router';
import { AxiosError, type AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { DxButton } from 'devextreme-vue/button';
import {
  DxEmailRule,
  DxForm,
  DxLabel,
  DxRequiredRule,
  DxSimpleItem,
  DxItem as FormItem,
} from 'devextreme-vue/form';
import { DxTabPanel, DxItem as TabItem } from 'devextreme-vue/tab-panel';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxItem, DxToolbar } from 'devextreme-vue/toolbar';
import { confirm } from 'devextreme/ui/dialog';
import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
  brugerId: {
    type: Number,
    required: false,
  },
});

const brugerDatasource = getBrugerDatasource();

const brugerForm = ref();
const brugerRolleList = ref();
const bruger = ref();
const fejlbesked = ref('');
const loading = ref(false);

async function gemBruger() {
  fejlbesked.value = '';
  const validation = brugerForm.value.instance.validate();
  if (validation.isValid === false) return;

  loading.value = true;
  let brugerId = null;
  let response;
  try {
    response = (await brugerDatasource.save(bruger.value)) as AxiosResponse<Bruger>;
    brugerId = response.data.id;
  } catch (err: unknown) {
    if (err instanceof AxiosError) {
      fejlbesked.value = err.response?.data?.Message || err.message;
    }
    if (err instanceof Error) {
      fejlbesked.value = err.message;
    }
    loading.value = false;
    return;
  }

  await brugerRolleList.value.saveChanges(brugerId);
  await brugerDatasource.reload();
  loading.value = false;

  router.push(ROUTE_BRUGERE);
}

async function sletBruger() {
  let result = confirm(
    'Er du sikker at du vil slette <b>' + bruger.value.email + '</b>?',
    'Slet bruger',
  );
  result.then(async (answer) => {
    if (answer) {
      await brugerDatasource.store().remove(bruger.value.id);
      brugerDatasource.reload();
      await router.push(ROUTE_BRUGERE);
    }
  });
}

async function fortryd() {
  if (props.brugerId && props.brugerId > 0)
    bruger.value = await brugerDatasource.store().byKey(props.brugerId);
  await router.push(ROUTE_BRUGERE);
}

watchEffect(async () => {
  if (props.brugerId && props.brugerId > 0) {
    bruger.value = await brugerDatasource.store().byKey(props.brugerId);
  } else {
    bruger.value = {};
  }
});

setTimeout(() => {
  if (props.brugerId == -1) brugerForm.value.instance.getEditor('fornavn').focus();
}, 200);
</script>

<template>
  <DxToolbar class="let-toolbar">
    <DxItem location="before">
      <LetBackButton text="Tilbage til listen" :route="ROUTE_BRUGERE" />
    </DxItem>
    <DxItem location="after">
      <LetButton
        :loading="loading"
        text="Gem bruger"
        :use-submit-behavior="false"
        @click="gemBruger"
        type="default"
      />
    </DxItem>
    <DxItem location="after">
      <DxButton text="Fortryd" @click="fortryd" />
    </DxItem>
    <DxItem location="after" locate-in-menu="always">
      <DxButton
        text="Slet bruger"
        v-if="brugerId && brugerId > 0"
        @click="sletBruger"
        type="danger"
        styling-mode="outlined"
      />
    </DxItem>
  </DxToolbar>

  <div v-if="bruger">
    <div class="container">
      <div class="bruger-detail">
        <form>
          <DxForm :form-data="bruger" ref="brugerForm" :showValidationSummary="true">
            <DxSimpleItem data-field="fornavn" :is-required="true" />
            <DxSimpleItem data-field="efternavn" :is-required="true" />
            <FormItem data-field="email">
              <DxTextBox v-model="bruger.email" value-change-event="keyup"></DxTextBox>
              <DxRequiredRule message="Email er påkrævet" />
              <DxEmailRule />
            </FormItem>
            <FormItem data-field="oprettetAf">
              <DxLabel text="Oprettet"></DxLabel>
              <div v-if="bruger.adInvitedDate">
                {{ dayjs(bruger.adInvitedDate).format('DD-MM-YYYY') }}&nbsp;{{
                  bruger.oprettetAf === 'Batch' ? '(Automatisk)' : 'af ' + bruger.oprettetAf
                }}
              </div>
            </FormItem>
            <DxSimpleItem>
              <DxLabel text=" " :showColon="false" />
              <div class="color-error">{{ fejlbesked }}</div>
            </DxSimpleItem>
          </DxForm>
        </form>
      </div>
      <DxTabPanel class="tabs">
        <TabItem title="Roller" icon="user">
          <template #default>
            <BrugerRolleList ref="brugerRolleList" class="tab-page" :bruger="bruger" />
          </template>
        </TabItem>
        <TabItem title="Notifikationer" icon="email">
          <template #default>
            <BrugerNotifikationer :bruger-id="bruger.id" class="tab-page" />
          </template>
        </TabItem>
        <TabItem title="Historik" icon="file">
          <template #default>
            <BrugerHistorik :bruger-id="bruger.id" class="tab-page" />
          </template>
        </TabItem>
      </DxTabPanel>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension.scss';

.tab-page {
  padding: 20px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 0;
}

.bruger-detail {
  flex: 100%;
}

.tabs {
  flex: 100%;
  margin-top: 7px;
}

@media (min-width: 1400px) {
  .myflex-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0;
    row-gap: 20px;
    gap: 20px;
  }

  .bruger-detail,
  .tabs {
    flex: 1;
  }
}
</style>
