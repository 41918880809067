import { createDevextremeDatasource, type LetDataSource } from '@/letapps-vue/utils/useRestApi';

let logDatasource: LetDataSource;

export function getLogDatasource() {
  if (!logDatasource) {
    logDatasource = createDevextremeDatasource('/api/LetappsLog', 'id');
  }
  return logDatasource;
}

let logDetailDatasource: LetDataSource;
export function getLogDetailDatasource() {
  if (!logDetailDatasource) {
    logDetailDatasource = createDevextremeDatasource('/api/LetappsLog', 'id');
  }
  return logDetailDatasource;
}
