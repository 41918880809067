import { createDevextremeDatasource, type LetDataSource } from '@/letapps-vue/utils/useRestApi';

let store: LetDataSource;

export function getRolleRettighedDatasource() {
  if (!store) {
    store = createDevextremeDatasource('/api/RolleRettigheder', 'id', { paginate: false });
  }
  return store;
}
