import { createDevextremeDatasource, type LetDataSource } from '@/letapps-vue/utils/useRestApi';

let store: LetDataSource;

export function getOrganisationDatasource() {
  if (!store) {
    store = createDevextremeDatasource('/api/Organisationer', 'id', { pageSize: 1020 });
  }

  return store;
}
