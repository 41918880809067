import { createDevextremeDatasource, type LetDataSource } from '@/letapps-vue/utils/useRestApi';

let store: LetDataSource;

export function getBrugerHistorikDatasource() {
  if (!store) {
    store = createDevextremeDatasource('/api/BrugerHistorik', 'id');
  }
  return store;
}
