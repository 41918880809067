<script setup lang="ts">
import LetPage from '@/letapps-vue/components/LetPage.vue';
import LogDetail from '@/pages/log/LogDetail.vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import LogGrid from './LogGrid.vue';

const route = useRoute();
const logId = computed(() => Number(route.params.id));
const showDetails = computed(() => !!route.params.id);
</script>

<template>
  <LetPage fit title="Log">
    <LogGrid v-show="!showDetails" />
    <LogDetail v-if="showDetails" :log-id="logId" />
  </LetPage>
</template>
s

<style scoped lang="scss">
.grid {
  height: 100%;
  min-height: 0;
}
</style>
