<script setup lang="ts">
import { getBrugerRolleDatasource } from '@/data/brugerRolleDatasource';
import { getRolleDatasource } from '@/data/rolleDatasource';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import type { BrugerRolle } from '@/letapps-vue/types/brugerRolle';
import type { Rolle } from '@/letapps-vue/types/rolle';
import axios from 'axios';
import dayjs from 'dayjs';
import { DxCheckBox } from 'devextreme-vue/check-box';
import DxScrollView from 'devextreme-vue/scroll-view';
import { DxTooltip } from 'devextreme-vue/tooltip';
import type { ValueChangedEvent } from 'devextreme/ui/check_box';
import { ref, watchEffect } from 'vue';

const props = defineProps({
  bruger: {
    type: Object,
    required: false,
  },
});

defineExpose({ saveChanges });

interface RolleData extends Rolle {
  brugerRolle?: BrugerRolle;
}

const rolleData = ref<RolleData[]>([]);

const brugerRolleDatasource = getBrugerRolleDatasource();
const rolleDatasource = getRolleDatasource();
const brugerRoller = ref<BrugerRolle[]>();
const ready = ref();

const roller = ref<Rolle[]>([]);
const changes = ref<{ [key: number]: any }>({});

watchEffect(async () => {
  if (props.bruger) brugerRolleDatasource.filter(['brugerId', '=', props.bruger.id]);
  brugerRoller.value = await brugerRolleDatasource.load();
  roller.value = await rolleDatasource.load();
  roller.value.forEach((r) => {
    rolleData.value.push({ ...r, brugerRolle: getBrugerRolle(r.id) });
  });
  ready.value = true;
});

function getBrugerRolle(rolleId: number) {
  if (!brugerRoller.value) return undefined;
  const found = brugerRoller.value.filter((brugerRolle) => brugerRolle.rolleId === rolleId);
  if (found.length > 0) {
    return found[0];
  }
  return undefined;
}

function grant(rolleId: number, brugerRolle: BrugerRolle | undefined, e: ValueChangedEvent) {
  if (!e.event) return; // Vi skal kun gøre noget, hvis en bruger har klikket.
  if (e.value === false) {
    if (changes.value[rolleId]?.action === 'add') {
      delete changes.value[rolleId];
    } else {
      changes.value[rolleId] = { action: 'remove', id: brugerRolle?.id };
    }
  }
  if (e.value === true) {
    if (changes.value[rolleId]?.action === 'remove') {
      delete changes.value[rolleId];
    } else {
      changes.value[rolleId] = { action: 'add', rolleId: rolleId };
    }
  }
}

function bestilVelkomstMail(rolleId: number, e: ValueChangedEvent) {
  if (!e.event) return; // Vi skal kun gøre noget, hvis en bruger har klikket.
  changes.value[rolleId].sendVelkomstmail = e.value;
}

function saveChanges(brugerId: number) {
  const promises = [];
  for (const [rolleId, change] of Object.entries(changes.value)) {
    if (change.action === 'remove') {
      promises.push(brugerRolleDatasource.store().remove(change.id));
    }
    if (change.action === 'add') {
      promises.push(
        brugerRolleDatasource.store().insert({
          brugerId: brugerId,
          rolleId: rolleId,
          sendVelkomstmail: change.sendVelkomstmail,
        }),
      );
    }
  }
  return Promise.all(promises);
}

async function sendMail(brugerRolle: BrugerRolle) {
  try {
    brugerRolle.loading = true;
    await axios.post(getAppConfig().apiRoot + '/api/BrugerRoller/sendmail/' + brugerRolle.id);
    brugerRolle.velkomstmailAfsendt = dayjs();
    brugerRolle.loading = false;
  } catch (error) {
    console.error(error);
  }
}
</script>

<template>
  <DxScrollView v-if="ready" class="list">
    <div class="grid-row header-row">
      <div class="col-rolle">Rolle</div>
      <div class="col-mail">Velkomstmail</div>
    </div>
    <div v-for="r in rolleData" :key="r.id">
      <div v-if="r.tilladEkstern || bruger?.email?.endsWith('@letpension.dk')" class="grid-row">
        <div class="col-rolle">
          <DxCheckBox
            :value="r.brugerRolle != null"
            @valueChanged="(e: ValueChangedEvent) => grant(r.id, r.brugerRolle, e)"
            class="checkbox"
          />
          <span class="rolle-navn">{{ r.navn }}</span>
          <i v-if="r.beskrivelse" class="dx-icon-info help" :id="`help-${r.id}`"></i><br />
          <DxTooltip
            v-if="r.beskrivelse"
            :hide-on-outside-click="false"
            :target="`#help-${r.id}`"
            show-event="mouseenter"
            hide-event="mouseleave"
          >
            <span class="help-tooltip">{{ r.beskrivelse }}</span>
          </DxTooltip>
        </div>
        <div class="col-mail">
          <div v-if="r.brugerRolle?.velkomstmailAfsendt">
            Afsendt
            {{ dayjs(r.brugerRolle.velkomstmailAfsendt).format('DD-MM-YYYY HH:mm') }}
            &nbsp;
            <span
              v-if="!r.brugerRolle.loading"
              class="send-mail-link"
              @click="sendMail(r.brugerRolle)"
              >Gensend nu</span
            >
          </div>
          <div v-else>
            <div v-if="r.velkomstmailMulig && changes[r.id]?.action === 'add'">
              <DxCheckBox
                :value="getBrugerRolle(r.id) != null"
                @valueChanged="(e: ValueChangedEvent) => bestilVelkomstMail(r.id, e)"
                class="checkbox"
              />
              <span class="rolle-navn">Send velkomstmail</span>
            </div>
            <div v-if="r.velkomstmailMulig && r.brugerRolle && !r.brugerRolle?.loading">
              <span
                class="send-mail-link"
                @click="
                  () => {
                    if (r.brugerRolle) sendMail(r.brugerRolle);
                  }
                "
                >Afsend nu</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </DxScrollView>
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension.scss';

.list {
  height: 100%;
}

.header-row {
  font-weight: bold;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 4px;
}
.grid-row {
  display: flex;
  flex-direction: row;
}

.col-rolle {
  width: 350px;
}

.col-mail {
  padding-top: 2px;
  flex: 1;
}

.checkbox {
  height: 25px;
}
.rolle-navn {
  padding-left: 6px;
}
.help {
  font-size: 18px;
  position: relative;
  top: 3px;
  padding-left: 4px;
}

.email {
  font-size: 18px;
  color: $letpension-blue;
}

.send-mail-link {
  color: $letpension-orange;
  text-decoration: underline;
  cursor: pointer;
}

.help-tooltip {
  font-size: 15px;
  // font-weight: 600;
}
</style>
