<script setup lang="ts">
import { useRoute } from 'vue-router';
import SideMenuSubMenu from './SideMenuSubMenu.vue';
import type { MenuItem } from '@/letapps-vue/types/menuitem';

defineProps<{
  item: MenuItem;
}>();

const route = useRoute();

function getActiveClass(item: MenuItem) {
  if (item.path && route.path.startsWith(item.path)) {
    return 'router-link-active';
  }
  return null;
}
</script>

<template>
  <RouterLink :to="item.path" :class="getActiveClass(item)">
    <div class="side-menu-item">
      {{ item?.text }}
    </div>
  </RouterLink>
  <SideMenuSubMenu v-if="item?.items" :menuItem="item" />
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension';

a {
  color: black;
  opacity: 1;
  text-decoration: none;
}

a:hover {
  .side-menu-item {
    opacity: 0.7;
  }
}

.router-link-active {
  .side-menu-item {
    color: $letpension-orange;
  }
}

.side-menu-item {
  font-size: 1.125rem;
  font-weight: 600;
  text-decoration: none;
  padding: 10px 0 5px 0;
  margin-left: 50px;
}
</style>
