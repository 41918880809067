<script setup lang="ts">
import axios from 'axios';
import { DxButton } from 'devextreme-vue/button';
import { DxForm, DxLabel, DxSimpleItem } from 'devextreme-vue/form';
import { DxItem, DxToolbar } from 'devextreme-vue/toolbar';
import LetButton from '@/letapps-vue/components/LetButton.vue';
import LetPage from '@/letapps-vue/components/LetPage.vue';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import { ref } from 'vue';
import { getErrorMessage } from '@/letapps-vue/utils/errorUtils';

interface Data {
  udfoerVending?: number;
  cpr?: string;
}
const vendTilFyiskUnderskriftForm = ref();
const data = ref<Data>({});
const fejlbesked = ref('');
const besked = ref('');
const loading = ref(false);
const vendingTilladt = ref(false);

async function vendTilFysiskUnderskrift(udfoerVending = 0) {
  data.value.udfoerVending = udfoerVending;
  data.value.cpr = data.value.cpr?.replaceAll('-', '');
  fejlbesked.value = '';
  besked.value = '';
  const validation = vendTilFyiskUnderskriftForm.value.instance.validate();
  if (validation.isValid === false) return;

  loading.value = true;
  try {
    const url = `${getAppConfig().apiRoot}/api/support/vendtilfysiskunderskrift`;
    const response = await axios.get(url, {
      params: data.value,
    });
    if (!response.data.success) {
      vendingTilladt.value = false;
      fejlbesked.value = response.data.message;
    } else {
      if (udfoerVending === 1) {
        vendingTilladt.value = false;
      } else {
        vendingTilladt.value = true;
      }
      besked.value = response.data.message;
    }
  } catch (e) {
    fejlbesked.value = getErrorMessage(e);
    loading.value = false;
    return;
  }
  loading.value = false;
}

function fortryd() {
  data.value = {};
}
</script>

<template>
  <LetPage :fit="true" title="Vend dokument til fysisk underskrift">
    <DxForm
      ref="vendTilFyiskUnderskriftForm"
      :form-data="data"
      :showValidationSummary="true"
      :width="500"
      @fieldDataChanged="
        () => {
          vendingTilladt = false;
        }
      "
    >
      <DxSimpleItem
        data-field="cpr"
        :is-required="true"
        :editorOptions="{
          valueChangeEvent: 'keyup',
          onValueChanged: markDirty,
        }"
      />
      <DxSimpleItem
        data-field="dokumentID"
        :is-required="true"
        :editorOptions="{
          valueChangeEvent: 'keyup',
          onValueChanged: markDirty,
        }"
      />
      />
      <DxSimpleItem>
        <DxLabel text=" " :showColon="false" />
        <DxToolbar>
          <DxItem location="before">
            <LetButton
              :loading="loading"
              text="Valider dokument"
              :use-submit-behavior="false"
              @click="vendTilFysiskUnderskrift(0)"
              type="default"
            />
          </DxItem>
          <DxItem location="before">
            <LetButton
              :loading="loading"
              text="Vend til fysisk"
              :use-submit-behavior="false"
              @click="vendTilFysiskUnderskrift(1)"
              type="default"
              :disabled="!vendingTilladt"
            />
          </DxItem>
          <DxItem location="before">
            <DxButton text="Nulstil" @click="fortryd" />
          </DxItem>
        </DxToolbar>
      </DxSimpleItem>
      <DxSimpleItem>
        <DxLabel text=" " :showColon="false" />
        <div class="color-error">{{ fejlbesked }}</div>
      </DxSimpleItem>
      <DxSimpleItem>
        <DxLabel text=" " :showColon="false" />
        <div>{{ besked }}</div>
      </DxSimpleItem>
    </DxForm>
  </LetPage>
</template>

<style scoped lang="scss"></style>
