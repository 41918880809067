import { createDevextremeDatasource, type LetDataSource } from '@/letapps-vue/utils/useRestApi';

let brugerDatasource: LetDataSource;
export function getBrugerDatasource() {
  if (!brugerDatasource) {
    brugerDatasource = createDevextremeDatasource('/api/Brugere', 'id');
  }
  return brugerDatasource;
}

let brugerDetailDatasource: LetDataSource;
export function getBrugerDetailDatasource() {
  if (!brugerDetailDatasource) {
    brugerDetailDatasource = createDevextremeDatasource('/api/Brugere', 'id');
  }
  return brugerDetailDatasource;
}
