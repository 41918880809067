<script setup lang="ts">
import { hasRettighed } from '@/letapps-vue/utils/auth';
import { useRoute } from 'vue-router';

const props = defineProps({ menuItem: Object });

const route = useRoute();

function getActiveClass() {
  return route.path.startsWith(props.menuItem?.path) ? 'sub-menu-active' : null;
}
</script>

<template>
  <div class="sub-menu" :class="getActiveClass()">
    <i class="dx-icon-spindown sub-menu-arrow"></i>
    <div v-for="(item, index) in menuItem?.items" :key="index">
      <RouterLink :to="item.path" v-if="!item.rettighed || hasRettighed(item.rettighed)">
        <div class="sub-menu-item">
          {{ item.text }}
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension';

.sub-menu {
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
  max-height: 0;

  &.sub-menu-active {
    max-height: 1000px;
    margin-bottom: 10px;
    transition:
      max-height,
      padding-bottom,
      padding-top 0.4s;
    padding: 0 25px 0 70px;
  }

  .sub-menu-arrow {
    display: none;
    color: $letpension-sand;
    font-size: 80px;
    position: absolute;
    top: -31px;
    left: 25px;
  }

  .sub-menu-item {
    padding: 2px 0;
  }

  a {
    text-decoration: none;
    color: black;
    font-weight: normal;
    font-size: 1.0625rem;

    &.router-link-active {
      color: $letpension-orange;
    }
  }

  &.blue {
    background-color: $letpension-blue;

    &.sub-menu-active {
      padding: 20px 25px 20px 50px;
    }

    .sub-menu-arrow {
      display: block;
    }

    a {
      color: white;
      font-weight: 600;
      font-size: 1.125rem;
    }
  }
}
</style>
