<script setup lang="ts">
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { DxPopup } from 'devextreme-vue/popup';
import { computed, ref, watch } from 'vue';
import LogLevelIcon from '@/pages/log/LogLevelIcon.vue';
import { formatDate } from 'devextreme/localization';
import { getLogDetailDatasource } from '@/data/logDatasource';
import LetBackButton from '@/letapps-vue/components/LetBackButton.vue';

const logDetailDatasource = getLogDetailDatasource();
const logEntry = ref();
const popupScreenshotVisible = ref(false);

const props = defineProps({
  logId: {
    type: Number,
    required: false,
  },
});

async function loadData() {
  logEntry.value = await logDetailDatasource.store().byKey(props.logId);
}

function showScreenshot() {
  popupScreenshotVisible.value = true;
}

const logEvent = computed(() => {
  if (logEntry.value.logEvent) return JSON.parse(logEntry.value.logEvent);
  return null;
});

watch(
  () => props.logId,
  () => {
    loadData();
  },
);

if (props.logId) loadData();
</script>

<template>
  <DxScrollView height="100%" v-if="logEntry">
    <LetBackButton text="Tilbage til log" route="/log" />
    <h2>
      <log-level-icon large :level="logEntry.level" />
      {{ formatDate(new Date(logEntry.timeStamp), 'dd-MM-yyyy HH:mm:ss') }}
    </h2>

    <div class="first-row">
      <div class="first-left">
        <div>{{ logEntry.application }} version {{ logEntry.buildNumber }}</div>
        <div>{{ logEntry.host }}</div>
        <div>{{ logEntry.user }}</div>
        <div>
          {{ logEntry.requestMethod }} {{ logEntry.requestPath }} ({{ logEntry.statusCode }})
        </div>
        <div>{{ logEntry.message }}</div>

        <div v-if="logEvent?.Browser">
          {{ logEvent.Browser.Name }} v. {{ logEvent.Browser.Version }} on {{ logEvent.Browser.Os }}
        </div>
      </div>

      <div v-if="logEntry.screenshot" class="first-right">
        <div @click="showScreenshot()" class="screenshot-container">
          <img class="screenshot" :src="logEntry.screenshot" />
        </div>
      </div>
    </div>

    <div v-if="logEntry.exception">
      <h2>Stacktrace</h2>
      <pre>{{ logEntry.exception }}</pre>
    </div>
  </DxScrollView>
  <DxPopup
    v-if="logEntry"
    v-model:visible="popupScreenshotVisible"
    :hide-on-outside-click="true"
    :full-screen="false"
    :drag-enabled="false"
    :show-title="true"
    :show-close-button="true"
    title="Screenshot"
    class="popup"
  >
    <img class="screenshot" :src="logEntry.screenshot" />
  </DxPopup>
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension';

.first-row {
  display: flex;
}

.first-left {
  width: 60%;
  flex-grow: 1;
}

.first-right {
  width: 40%;
  flex-grow: 1;
}

.screenshot-container {
  cursor: pointer;
}

.screenshot {
  width: 100%;
  border: 1px solid lightgray;
}

.popup {
  width: 10%;
}
</style>
data/logDatasource
