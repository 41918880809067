<script setup lang="ts">
import { DxColumn, DxDataGrid, DxEditing } from 'devextreme-vue/data-grid';
import { getRolleDatasource } from '@/data/rolleDatasource';
import type { RowClickEvent, RowUpdatingEvent } from 'devextreme/ui/data_grid';
import type { Rolle } from '@/letapps-vue/types/rolle';

const emit = defineEmits<{
  rolleClick: [rolle: Rolle];
}>();

const rolleDatasource = getRolleDatasource();

function onRowUpdating(options: RowUpdatingEvent) {
  options.newData = { ...options.oldData, ...options.newData };
}

function onRowClick(e: RowClickEvent) {
  emit('rolleClick', e.data as Rolle);
}
</script>

<template>
  <DxDataGrid
    ref="grid"
    :data-source="rolleDatasource"
    :focused-row-enabled="true"
    :row-alternation-enabled="true"
    :show-borders="true"
    :show-column-lines="false"
    :hover-state-enabled="true"
    @row-updating="onRowUpdating"
    @row-click="onRowClick"
  >
    <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="form" />
    <DxColumn data-field="navn" caption="Navn" sort-order="asc" />
    <DxColumn data-field="beskrivelse" caption="Beskrivelse" />
    <DxColumn data-field="tilladLokalAdmin" :visible="false" />
  </DxDataGrid>
</template>
data/rolleDatasource
