<script setup lang="ts">
const props = defineProps({
  level: String,
  large: Boolean,
});

function getLevelIconClasses() {
  const classes = ['level-icon', `level-icon-${props.level}`];

  if (props.large) classes.push('level-icon-large');

  if (props.level === 'Warning') {
    classes.push('dx-icon-warning');
  }
  if (props.level === 'Information') {
    classes.push('dx-icon-info');
  }
  if (props.level === 'Error') {
    classes.push('dx-icon-clearsquare');
  }
  return classes;
}
</script>

<template>
  <i :class="getLevelIconClasses()" :title="level" />
</template>

<style scoped lang="scss">
@import '@/letapps-vue/css/variables.letpension';

.level-icon {
  font-size: 16px;
  position: relative;
  top: 2px;
}

.level-icon-large {
  font-size: 24px;
}

.level-icon-Information {
  color: $letpension-info;
}

.level-icon-Warning {
  color: $letpension-warning;
}

.level-icon-Error {
  color: $letpension-error;
}
</style>
