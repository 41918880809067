import 'devextreme/dist/css/dx.common.css';
import '@/css/letadmin.scss';
import 'primeicons/primeicons.css';
import { createApp } from 'vue';
import { initAppConfig } from '@/letapps-vue/utils/appConfig';
import { initAxios } from '@/letapps-vue/utils/axios';
import { loadMessages, locale } from 'devextreme/localization';
import { requireAuthentication } from '@/letapps-vue/utils/auth';
import PrimeVue from 'primevue/config';
import router from './router';
import danishMessages from './da.json';
import { initSentry } from '@/letapps-vue/utils/sentry';
import App from './App.vue';
import { letpensionPreset } from '@/letapps-vue/primevue/letpensionPreset';

loadMessages(danishMessages);
locale('da');

(async () => {
  await initAppConfig();

  requireAuthentication();

  const app = createApp(App);

  await initSentry(app, router);
  await initAxios();

  app.use(PrimeVue, {
    theme: {
      preset: letpensionPreset,
      options: {
        prefix: 'p',
        darkModeSelector: 'none',
        cssLayer: false,
      },
    },
  });

  app.use(router);
  app.mount('#app');
})();
