<script setup lang="ts">
import { getBrugerHistorikDatasource } from '@/data/brugerHistorikDatasource';
import { DxDataGrid } from 'devextreme-vue';
import { DxColumn, DxPaging, DxScrolling } from 'devextreme-vue/data-grid';
import { watchEffect } from 'vue';

const props = defineProps({
  brugerId: { type: Number },
});

const brugerHistorikDatasource = getBrugerHistorikDatasource();

watchEffect(async () => {
  if (props.brugerId) {
    brugerHistorikDatasource.filter(['brugerId', '=', props.brugerId]);
  }
});
</script>

<template>
  <DxDataGrid
    :data-source="brugerHistorikDatasource"
    :remote-operations="true"
    :row-alternation-enabled="true"
    :show-column-lines="false"
    :hover-state-enabled="true"
    :show-borders="true"
  >
    <DxPaging :page-size="100" />
    <DxScrolling mode="virtual" row-rendering-mode="virtual" />
    <DxColumn
      data-field="tidspunkt"
      data-type="date"
      format="dd-MM-yyyy HH:mm"
      css-class="numbers"
      sort-order="desc"
      :width="140"
    >
    </DxColumn>
    <DxColumn data-field="systemKommentar" caption="Hændelse" />
    <DxColumn data-field="rettetAf" caption="Udført af" :width="200" />
  </DxDataGrid>
</template>

<style scoped>
.list {
  height: 100%;
}
</style>
