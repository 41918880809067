import type { MenuItem } from './letapps-vue/types/menuitem';
import { RettighedsType } from './types/rettighed';

export default [
  {
    text: 'Brugeradministration',
    path: '/brugeradm',
    items: [
      {
        text: 'Brugere',
        path: '/brugeradm/brugere',
        rettighed: RettighedsType.AdminBruger,
      },
      {
        text: 'Roller',
        path: '/brugeradm/roller',
        rettighed: RettighedsType.AdminRolle,
      },
    ],
  },
  {
    text: 'Forretningssupport',
    rettighed: RettighedsType.AdminSupportSletRevision,
    path: '/support',
    items: [
      {
        text: 'Slet revision',
        path: '/support/sletrevision',
        rettighed: RettighedsType.AdminSupportSletRevision,
      },
      {
        text: 'Vend dokument til fysisk underskrift',
        path: '/support/vendtilfysiskunderskrift',
        rettighed: RettighedsType.AdminSupportVendTilFysiskUnderskrift,
      },
    ],
  },
  {
    text: 'Log',
    path: '/log',
    rettighed: RettighedsType.AdminLog,
  },
  {
    text: 'Visual guide',
    path: '/visualguide',
    rettighed: RettighedsType.DeveloperMode,
  },
  {
    text: 'Test page',
    path: '/test',
    rettighed: RettighedsType.DeveloperMode,
  },
] as MenuItem[];
