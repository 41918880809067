import { createDevextremeDatasource, type LetDataSource } from '@/letapps-vue/utils/useRestApi';

let store: LetDataSource;

export function getRettighedDatasource() {
  if (!store) {
    store = createDevextremeDatasource('/api/RettighederEnum', 'id', {
      sort: [{ selector: 'navn' }],
      paginate: false,
    });
  }

  return store;
}
