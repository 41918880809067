<script setup lang="ts">
import { ref } from 'vue';
import LetPage from '@/letapps-vue/components/LetPage.vue';
import RollerGrid from './RollerGrid.vue';
import RolleRettighedList from './RolleRettighedList.vue';
import type { Rolle } from '@/letapps-vue/types/rolle';

const selectedRolle = ref<Rolle | undefined>();

function onRolleClick(rolle: Rolle) {
  selectedRolle.value = rolle;
}
</script>

<template>
  <LetPage fit title="Roller">
    <div class="container">
      <RollerGrid class="left" @rolle-click="onRolleClick" />
      <RolleRettighedList v-if="selectedRolle" :rolle="selectedRolle" class="right l-pl" />
    </div>
  </LetPage>
</template>

<style scoped lang="scss">
.container {
  display: flex;
  height: 100%;
}

.left {
  flex: 1;
}

.right {
  width: 500px;
  padding-left: 16px;
  padding-top: 35px;
  height: 100%;
}
</style>
