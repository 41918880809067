<script setup lang="ts">
import { authState, signOut } from '@/letapps-vue/utils/auth';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import { DxButton } from 'devextreme-vue/button';
import LetPage from '@/letapps-vue/components/LetPage.vue';

const appConfig = getAppConfig();
</script>

<template>
  <LetPage title="Profil">
    <div>
      {{ authState.bruger.fornavn }} {{ authState.bruger.efternavn }} ({{ authState.bruger.email }})
    </div>
    <DxButton @click="signOut" text="Log ud" type="default" />
    <br /><br />

    <div>version: {{ appConfig.buildNumber }}</div>
    <br /><br />
    <div>
      <h3>Rettigheder</h3>
      <ul>
        <li v-for="rettighed in authState.bruger.rettigheder" :key="rettighed">{{ rettighed }}</li>
      </ul>
    </div>
  </LetPage>
</template>
